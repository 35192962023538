* {
  margin: 0;
  padding: 0;
  overflow-x: hidden;
  font-family: "Josefin Sans", sans-serif;
}

/* .navbar-light {
  background-color: #6f00ff;
  overflow-y: hidden;
} */

body {
  overflow-x: hidden;
}

@viewport {
  width: device-width;
  zoom: 1;
  user-zoom: fixed;
  max-zoom: 1;
}

/* Home */

.landing-bg {
  width: 1550px;
  z-index: -90;
  position: absolute;
  left: 0;
  top: 0;
  height: 670px;
  overflow-x: hidden;
}

.home-firstline {
  font-size: 55px;
  margin-top: 60px;
  font-weight: bold;
  width: 100%;
  color: white;
}

.home-container {
  margin-left: 150px;
  margin-right: 150px;
  display: flex;
  justify-content: space-between;
  overflow-x: hidden;
}

.home-rightcontainer {
  margin-top: 70px;
  overflow-x: hidden;
}

.home-secondline {
  font-size: 75px;
  margin-top: -25px;
  font-weight: bold;
  width: 100%;
  color: white;
}

.home-button {
  margin-top: 30px;
  padding: 12px 70px;
  font-weight: bold;
  color: white;
  font-size: 20px;
  border: none;
  margin-bottom: 80px;
  margin-right: 15px;
  transition: background-color 0.3s ease-in-out;
  background-color: rgb(38, 0, 87);
  border-radius: 5px;
}

.home-button:hover {
  background-color: rgb(76, 0, 175);
  cursor: pointer;
}
.home-button2 {
  margin-top: 30px;
  padding: 12px 70px;
  font-weight: bold;
  font-size: 20px;
  border: none;
  color: white;
  /* border: 1px solid black; */
  margin-bottom: 80px;
  margin-right: 15px;
  transition: background-color 0.3s ease-in-out;
  background-color: rgb(11, 0, 24);
  border-radius: 5px;
}

.home-button2:hover {
  background-color: rgb(41, 0, 95);
  cursor: pointer;
}

.grass {
  opacity: 0.2;
  position: absolute;
  right: 0;
  width: 100px;
  height: 500px;
  margin-top: 220px;
  margin-right: -10px;
  background-image: url("../img/cartoongrass.png");
  background-size: cover;
  animation: blowing 5s ease-in-out infinite;
}

@keyframes blowing {
  0% {
    background-position: 0 0;
  }
  50% {
    background-position: -5px -5px;
  }
  100% {
    background-position: 0 0;
  }
}

.grass2 {
  opacity: 0.2;
  position: absolute;
  left: 0;
  rotate: 180deg;
  width: 100px;
  height: 500px;
  margin-top: 220px;
  margin-left: -10px;
  background-image: url("../img/cartoongrass.png");
  background-size: cover;
  animation: blowing 5s ease-in-out infinite;
}

@keyframes blowing2 {
  0% {
    background-position: 0 0;
  }
  50% {
    background-position: -5px -5px;
  }
  100% {
    background-position: 0 0;
  }
}

.home-checkmark {
  height: 25px;
  width: 25px;
  margin-right: 10px;
}

.home-listdiv {
  display: flex;
}

.home-listul {
  padding-left: 0px;
  font-size: 18px;
  margin-top: 25px;
  color: white;
}

.home-placeholdervideo {
  border-radius: 10px;
  height: 300px;
  width: 350px;
  background-color: black;
}

/* Nav */

.nav-container {
  position: relative;
  top: 0;
  display: flex;
  justify-content: space-between;
  width: 1300px;
  /* margin-top: 25px; */
  /* margin-bottom: 75px; */
  overflow-x: hidden;
}

.sticky {
  position: fixed;
  top: 0;
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: -5px;
  padding-bottom: 15px;
  background-color: rgba(111, 0, 255, 1);
  box-shadow: rgba(100, 100, 111, 0.4) 0px 7px 29px 0px;
  transition: ease-in-out 0.3s;
  z-index: 10000;
  overflow-x: hidden;
}

.nav-logo {
  width: 130px;
  height: 30px;
  margin-left: 150px;
  margin-top: 25px;
  z-index: 100000000000;
}

.nav-navlist {
  display: flex;
  margin-bottom: -7px;
  overflow-x: hidden;
}

.nav-item {
  text-decoration: none;
  color: white;
  font-size: 19px;
  margin-right: 10px;
  overflow-x: hidden;
}
.nav-process {
  text-decoration: none;
  color: white;
  font-size: 19px;
  margin-right: 10px;
}

.nav-process:hover {
  color: white;
}

.nav-process::before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 25;
  height: 3px;
  width: 0;
  background-color: white;
  transition: width 0.5s ease;
}

.nav-process:hover::before {
  width: 13.6%;
}

.nav-work {
  text-decoration: none;
  color: white;
  font-size: 19px;
  margin-right: 10px;
}

.nav-work:hover {
  color: white;
}

.nav-work::before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 25;
  height: 3px;
  width: 0;
  background-color: white;
  transition: width 0.5s ease;
}

.nav-work:hover::before {
  width: 11.8%;
}
.nav-contact {
  text-decoration: none;
  color: white;
  font-size: 19px;
  margin-right: 10px;
}

.nav-contact:hover {
  color: white;
}

.nav-contact::before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 25;
  height: 3px;
  width: 0;
  background-color: white;
  transition: width 0.5s ease;
}

.nav-contact:hover::before {
  width: 9%;
}
.nav-pricing {
  text-decoration: none;
  color: white;
  font-size: 19px;
  margin-right: 10px;
}

.nav-pricing:hover {
  color: white;
}

.nav-pricing::before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 25;
  height: 3px;
  width: 0;
  background-color: white;
  transition: width 0.5s ease;
}

.nav-pricing:hover::before {
  width: 7.4%;
}

.nav-navlist li {
  margin-right: 20px;
  list-style: none;
  font-weight: lighter;
  overflow-x: hidden;
}

.nav-button {
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 7px;
  padding-top: 13px;
  font-weight: bold;
  font-size: 19px;
  border: none;
  margin-top: 13px;
  color: white;
  border-radius: 5px;
  /* background-color: rgb(17, 232, 236, 0.5); */
  /* background-color: rgba(111, 0, 255, 255); */
  background-color: rgb(39, 0, 90);
  transition: background-color 0.3s ease-in-out;
}

.nav-button:hover {
  background-color: rgb(63, 0, 146);
  cursor: pointer;
}

.nav-lidiv {
  display: flex;
  margin-top: 25px;
}

.nav-item:hover {
  color: white;
}

/* Section 2 */
.section2-container {
  margin-top: -10px;
  padding-top: 0px;
  /* margin-left: 100x; */
  margin-left: -50px;
  margin-right: 150px;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.section2-background {
  /* background-color: #ffffff; */
  margin-top: 120px;
}

.section2-title {
  color: black;
  font-size: 50px;
  font-weight: 900;
  text-align: center;
  padding-top: 50px;
}

.section2-subtitle {
  font-size: 18px;
  font-weight: lighter;
  text-align: center;
}

.section2-italic {
  font-style: italic;
}

.section2-paintbrushimg {
  height: 75px;
  width: 75px;
  margin-bottom: 15px;
}

.section2-lockimg {
  height: 75px;
  width: 60px;
  margin-bottom: 15px;
}

.section2-paintbrushimg:hover {
  top: -10px;
  opacity: 0.8;
}

.section2-toprow {
  margin-left: 150px;
  display: flex;
  padding-top: 50px;
  padding-left: 50px;
}

.section2-toprow ul {
  list-style: none;
}

.section2-card {
  background-color: #ffffff;
  padding: 50px 20px;
  margin-right: 50px;
  margin-bottom: 50px;
  width: 1000px;
  box-shadow: rgba(100, 100, 111, 0.4) 0px 7px 15px 0px;
  position: relative;
  top: 0;
  /* transition: right 2s ease; */
  transition: background-color ease 1s, top ease 0.5s;
  /* transition: top ease 0.5s; */
}

.section2-card:hover {
  top: -20px;
  background-color: rgba(111, 0, 255, 0.3);
  /* right: -20px; */
}

.section2-service {
  font-weight: 900;
  font-size: 21px;
}

.section2-list {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin-left: -20px;
  font-size: 17px;
}

/* Section 3 */

.section3-container {
  margin-left: 200px;
  margin-right: 200px;
  padding-bottom: 130px;
  padding-top: 130px;
  display: flex;
  flex-direction: column;
}

.section3-backgroundimg {
  background-image: url("../img/darkergraphicbg.jpg");
  background-attachment: fixed;
}

.section3-title {
  color: white;
  text-align: center;
  font-size: 45px;
  font-weight: 900;
}

.section3-subtitle {
  color: white;
  text-align: center;
  font-size: 22px;
  font-weight: lighter;
}

.section3-pop {
  font-weight: 900;
}

.section3-button {
  align-self: center;
  justify-self: center;
  margin-top: 20px;
  padding: 12px 70px;
  font-weight: bold;
  font-size: 20px;
  border: none;
  transition: background-color 0.3s ease-in-out;
  background-color: rgba(111, 0, 255, 255);

  border-radius: 5px;
}

.section3-button:hover {
  background-color: rgb(161, 94, 248);
  cursor: pointer;
}

.section3-link {
  align-self: center;
  justify-self: center;
}

/* .section3-mowingicon {
  background-image: url("../img/lawnmowingicon.png");
  animation: move-right 15s linear infinite;
  background-repeat: no-repeat;
  background-size: 50px;
} */

/* Section 4 */
.section4-container {
  /* margin-top: 20px; */
  padding-top: 20px;
  /* margin-left: 100x; */
  margin-left: -50px;
  margin-right: 150px;
  display: flex;
  flex-direction: column;
}

.section4-title {
  color: black;
  font-size: 50px;
  font-weight: 900;
  text-align: center;
  padding-top: 130px;
}

.section4-card {
  background-color: #ffffff;
  margin-right: 50px;
  margin-bottom: 50px;
  border: 2px solid rgba(111, 0, 255, 1);
  width: 100%;
  height: 250px;
  box-shadow: rgba(100, 100, 111, 0.4) 0px 7px 29px 0px;
  position: relative;
  top: 0;
  transition: top ease 0.5s;
  overflow-x: hidden;
  overflow-y: hidden;
}

.section4-cardtextcontainer {
  display: flex;
  flex-direction: column;
  margin-right: 20px;
  width: 33%;
  text-align: center;
  margin-top: -30px;
}

.section4-img {
  width: 100%;
  height: 100%;
  transition: transform 0.8s ease;
  overflow-x: hidden;
  overflow-y: hidden;
}

.section4-img:hover {
  opacity: 0.6;
  transform: scale(1.1);
}

.section4-btn {
  display: none;
}

.section4-card:hover button {
  display: block;
  position: absolute;
  top: 0;
  text-align: center;
  padding: 10px 15px;
  font-weight: bold;
  border: none;
  background-color: rgb(100, 0, 230);
  color: white;
  border-radius: 5px;
  margin-top: 110px;
  margin-left: 120px;
}

.section4-card:hover {
  background-color: #ccc;
}

.section4-bush {
  position: absolute;
  right: 0;
  width: 150px;
  height: 500px;
  margin-top: 50px;
}
.section4-bush2 {
  position: absolute;
  rotate: 70deg;
  left: 0;
  width: 250px;
  height: 300px;
  margin-top: 350px;
}
/* Section 5 */
.section5-background {
  background-color: #ffffff;
}

.section5-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 170px;
}

.section5-title {
  font-size: 50px;
  font-weight: bold;
  overflow-y: hidden;
  overflow-x: hidden;
}

.section5-subtitle {
  font-size: 17px;
  text-align: center;
  width: 50%;
  margin-top: 5px;
  margin-bottom: 50px;
  overflow-y: hidden;
  overflow-wrap: hidden;
}

.section5-strategybtn {
  padding: 15px 25px;
  font-size: 22px;
  font-weight: bolder;
  color: black;
  border: 1px solid #ccc;
  background-color: rgb(255, 255, 255);
  transition: background-color 0.3s ease-in-out;
}

.section5-strategybtn:focus {
  background-color: rgba(111, 0, 255, 255);
  border: solid 3px black;
}

.section5-strategybtn:hover {
  background-color: rgba(111, 0, 255, 255);
}
.section5-designbtn {
  padding: 15px 25px;
  border: 1px solid #ccc;
  background-color: rgb(255, 255, 255);
  font-size: 22px;
  font-weight: bolder;
  color: black;
  transition: background-color 0.3s ease-in-out;
}

.section5-designbtn:focus {
  background-color: rgba(111, 0, 255, 255);
  border: solid 3px black;
}

.section5-designbtn:hover {
  background-color: rgba(111, 0, 255, 255);
}
.section5-developmentbtn {
  padding: 15px 25px;
  border: 1px solid #ccc;
  background-color: rgb(255, 255, 255);
  font-size: 22px;
  font-weight: bolder;
  color: black;
  transition: background-color 0.3s ease-in-out;
}

.section5-developmentbtn:focus {
  background-color: rgba(111, 0, 255, 255);
  border: solid 3px black;
}

.section5-developmentbtn:hover {
  background-color: rgba(111, 0, 255, 255);
}
.section5-maintenancebtn {
  padding: 15px 25px;
  border: 1px solid #ccc;
  background-color: rgb(255, 255, 255);
  font-size: 22px;
  font-weight: bolder;
  color: black;
  transition: background-color 0.3s ease-in-out;
}

.section5-maintenancebtn:focus {
  background-color: rgba(111, 0, 255, 255);
  border: solid 3px black;
}

.section5-maintenancebtn:hover {
  background-color: rgba(111, 0, 255, 255);
}
.section5-launchbtn {
  padding: 15px 25px;
  border: 1px solid #ccc;
  background-color: rgb(255, 255, 255);
  font-size: 22px;
  font-weight: bolder;
  color: black;
  transition: background-color 0.3s ease-in-out;
}

.section5-launchbtn:focus {
  background-color: rgba(111, 0, 255, 255);
  border: solid 3px black;
}

.section5-launchbtn:hover {
  background-color: rgba(111, 0, 255, 255);
}
.section5-maintainbtn {
  padding: 15px 25px;
  border: 1px solid #ccc;
  background-color: rgb(255, 255, 255);
  font-size: 22px;
  font-weight: bolder;
  color: black;
  transition: background-color 0.3s ease-in-out;
}

.section5-maintainbtn:focus {
  background-color: rgba(111, 0, 255, 255);
  border: solid 3px black;
}

.section5-maintainbtn:hover {
  background-color: rgba(111, 0, 255, 255);
}

.section5-strategy-text {
  display: flex;
  margin-left: 350px;
  margin-top: 35px;
}

.section5-strategy-text-hidden {
  display: none;
}
.section5-design-text {
  display: flex;
  margin-left: 350px;
  margin-top: 35px;
}

.section5-design-text-hidden {
  display: none;
}
.section5-development-text {
  display: flex;
  margin-left: 350px;
  margin-top: 35px;
}

.section5-development-text-hidden {
  display: none;
}
.section5-maintenance-text {
  display: flex;
  margin-left: 350px;
  margin-top: 35px;
}

.section5-maintenance-text-hidden {
  display: none;
}
.section5-launch-text {
  display: flex;
  margin-left: 350px;
  margin-top: 35px;
}

.section5-launch-text-hidden {
  display: none;
}
.section5-maintain-text {
  display: flex;
  margin-left: 350px;
  margin-top: 35px;
}

.section5-maintain-text-hidden {
  display: none;
}

.section5-contentcontainer {
  width: 40%;
  margin-left: 100px;
}

.section5-img {
  width: 350px;
  height: 330px;
}

.section5-contenttitle {
  font-size: 35px;
  font-weight: 900;
  color: black;
}

/* Section 6 */

.section6-background {
  background-image: url("../img/cbg.jpg");
  background-repeat: no-repeat;
  margin-top: 100px;
}

.section6-container {
  height: 650px;
  width: 100%;
  display: flex;
}

.section6-form {
  width: 50%;
  display: flex;
  flex-direction: column;
  margin-left: 250px;
  /* height: 1000px; */
}

.section6-btn {
  margin-top: 30px;
  padding: 12px 30px;
  font-weight: bold;
  color: white;
  font-size: 20px;
  width: 85%;
  border: none;
  margin-bottom: 80px;
  margin-right: 15px;
  transition: background-color 0.3s ease-in-out;
  background-color: rgb(38, 0, 87);
  border-radius: 5px;
}

.section6-btn:hover {
  background-color: rgb(58, 0, 133);
  cursor: pointer;
}

.section6-contactcta {
  width: 50%;
  margin-left: 50px;
  margin-top: 175px;
}

.section6-boldtext {
  font-size: 55px;
  font-weight: bold;
  color: white;
}

.section6-subtext {
  font-weight: lighter;
  color: white;
}

.section6-email {
  width: 20%;
}

.section6-emailinput {
  width: 40%;
  border-radius: 0px;
  border: 0px solid black;
  background-color: rgb(52, 0, 121);
  padding: 15px 10px;
  border-radius: 5px;
  margin-right: 15px;
  margin-top: 175px;
}

.section6-emailinput:focus {
  /* border: 1px solid black; */
}

.section6-emailinput::placeholder {
  color: rgb(255, 255, 255);
}
.section6-nameinput {
  width: 40%;
  border-radius: 0px;
  border: 0px solid black;
  background-color: rgb(52, 0, 121);
  padding: 15px 10px;
  border-radius: 5px;
  margin-right: 15px;
  margin-top: 15px;
}

.section6-nameinput:focus {
  /* border: 1px solid black; */
}

.section6-nameinput::placeholder {
  color: rgb(255, 255, 255);
}

.section6-textinput {
  width: 62.7%;
  border-radius: 0px;
  border: 0px solid black;
  background-color: rgb(52, 0, 121);
  padding: 15px 10px;
  margin-top: 15px;
}

.section6-textinput::placeholder {
  color: rgb(255, 255, 255);
}

/* Section 7 */

.section7-background {
  background-color: rgb(29, 0, 66);
}
.section7-servicecontainer {
  display: flex;
  font-size: 13px;
}

.section7-container {
  height: 285px;
  width: 100%;
  margin-left: 25px;
  margin-top: 75px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow-x: hidden;
}

.section7-sections {
  width: 20%;
  margin-right: 75px;
}

.section7-logo {
  width: 130px;
  height: 30px;
}

.section7-about {
  color: white;
  font-weight: lighter;
  margin-top: 55px;
}

.section7-nav {
  color: white;
  font-size: 18px;
}

.section7-nav1 {
  color: white;
  font-size: 18px;
}

.section7-title {
  color: white;
  font-size: 18px;
  font-weight: bold;
}
.section7-subtext {
  font-weight: lighter;
  color: white;
}

/* Section 8 */

.section8-background {
  background-color: rgb(23, 0, 54);
}

.section8-container {
  height: 120px;
  width: 100%;
}

.section8-copyright {
  text-align: center;
  margin: 40px;
  color: rgb(204, 204, 204);
}

.section8-policycontainer {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: -27px;
}

.section8-nostyle {
  text-decoration: none;
}

.section8-tos {
  margin-right: 20px;
  color: rgb(204, 204, 204);
}

.section8-pp {
  color: rgb(203, 203, 203);
}

/* Section 1 Pricing */

.section1pricing-flex {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.section1pricing-subtitle {
  font-size: 20px;
  width: 50%;
  margin-bottom: 25px;
  margin-top: 30px;
  text-align: center;
}

.section1pricing-firstline {
  font-size: 45px;
  margin-top: 60px;
  font-weight: bold;
  width: 100%;
  text-align: center;
  color: black;
}
.section1pricing-background {
  /* background-color: rgba(111, 0, 255, 255); */
}
.section1pricing-navbackground {
  height: 82px;
  width: 100%;
  z-index: -1;
  background-color: rgba(111, 0, 255, 1);
  position: absolute;
  top: 0;
  left: 0;
}

.section1pricing-secondline {
  font-size: 60px;
  margin-top: -25px;
  font-weight: bold;
  width: 100%;
  text-align: center;
  color: black;
}

.section1pricing-subsubtitle {
  font-size: 15px;
  font-style: italic;
  font-weight: 900;
  width: 40%;
  text-align: center;
  margin-bottom: 25px;
}

.section1pricing-bold {
  font-weight: 900;
}

.section1pricing-button2 {
  margin-top: 30px;
  padding: 12px 70px;
  font-weight: bold;
  font-size: 20px;
  border: none;
  margin-bottom: 170px;
  margin-right: 15px;
  transition: background-color 0.3s ease-in-out;
  background-color: rgba(31, 255, 79, 0.5);
  border-radius: 5px;
}

.section1pricing-button2:hover {
  background-color: rgb(0, 105, 23);
  cursor: pointer;
}

/* Section2 pricing */

.section2pricing-banner {
  z-index: 100000;
  background-color: rgb(89, 12, 221);
  padding-top: 15px;
  margin-bottom: 25px;
  width: 1070px;
}

.section2pricing-bannertext {
  color: white;
  font-weight: 900;
  font-size: 20px;
}

.section2pricing-titles {
  font-size: 25px;
  font-weight: 900;
  margin-bottom: 0px;
}

.section2pricing-subtitle {
  font-size: 17px;
}

.section2pricing-toprow {
  margin-left: 100px;
  display: flex;
  height: 970px;
  margin-top: 50px;
  margin-bottom: 50px;
  box-shadow: rgba(100, 100, 111, 0.4) 0px 7px 29px 0px;
  overflow-y: hidden;
}

.section2pricing-card {
  background-color: #ffffff;
  padding: 82px 20px;
  /* margin-bottom: 50px; */
  width: 100%;
  border: 1px solid rgba(0, 0, 0, 0.2);
  position: relative;
  top: 0;
  overflow-y: hidden;
  /* transition: right 2s ease; */
  transition: background-color ease 1s, top ease 0.5s;
  /* transition: top ease 0.5s; */
}

.section2pricing-card1 {
  background-color: #f1f4ff;
  border: 1px solid rgb(153, 144, 255) !important;
  padding: 0px 0px;
  height: 100%;
  width: 100%;
  border: 1px solid rgba(0, 0, 0, 0.2);
  position: relative;
  top: 0;
  overflow-y: hidden;
  overflow-x: hidden;
  transition: background-color ease 1s, top ease 0.5s;
}

.section2pricing-list {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin-left: -20px;
  font-size: 17px;
  margin-bottom: 0px;
  padding-bottom: 0px;
}

.section2pricing-list li {
  margin-bottom: 0px;
  padding-bottom: 0px;
}

.section2pricing-pricelist {
  display: flex;
  overflow-y: hidden;
  margin-right: 25px;
  margin-top: -18px;
  padding-bottom: 0px;
  margin-bottom: 0px;
}

.section2pricing-benefit {
  display: flex;
}

.section2pricing-crossoutpricelist {
  margin-right: 20px;
  display: flex;
  overflow-y: hidden;
}

.section2pricing-crossout {
  height: 2px;
  width: 29px;
  margin-top: 20px;
  margin-right: -29px;
  background-color: #919090;
}

.section2pricing-blurredprice {
  color: #919090;
  margin-top: 10px;
}

.section2pricing-usd {
  font-size: 13px;
  color: #919090;
  padding-top: 5px;
  margin-bottom: 0px;
}

.section2pricing-dollarsign {
  font-size: 13px;
  padding-top: 5px;
  overflow-x: hidden;
  overflow-y: hidden;
  margin-bottom: 0px;
}

.section2pricing-price {
  font-size: 65px;
  height: 73px;
  overflow-y: hidden;
  font-weight: 900;
  margin-bottom: 0px;
}

.section2pricing-month {
  margin-top: 45px;
  margin-bottom: 0px;
}

.section2pricing-subtext {
  color: #919090;
  font-size: 15px;
  /* width: 50%; */
  /* margin-left: 85px; */
  text-align: center;
}

.section2pricing-divider {
  height: 1px;
  width: 250px;
  background-color: rgba(0, 0, 0, 0.2);
}

.section2pricing-img {
  width: 20px;
  height: 20px;
  margin-right: 5px;
}

/* Section3 Pricing */

.section3pricing-title {
  color: black;
  width: 60%;
  font-size: 55px;
  font-weight: 900;
  margin-left: 300px;
  text-align: center;
  padding-top: 50px;
}

.section3pricing-titleslist {
  margin-top: 98px;
  display: flex;
  flex-direction: column;
  font-size: 18px;
}

.section3pricing-titleslist li {
  margin-bottom: 120px;
}

.section3pricing-ustitle {
  margin-top: 50px;
}

.section3pricing-container {
  display: flex;
  flex-direction: column;
  /* justify-content: space-around; */
  margin-left: 150px;
  margin-right: 150px;
}

.section3pricing-blank {
  color: white;
}

.section3pricing-colcontainer {
  display: flex;
  flex-direction: column;
}

.section3pricing-col1 {
  margin-top: 40px;
  margin-left: 60px;
  font-size: 18px;
  margin-right: 60px;
  box-shadow: rgba(100, 100, 111, 0.4) 0px 7px 29px 0px;
}

.section3pricing-col1list {
  display: flex;
  background-color: #ffffff;
}

.section3pricing-col1list li {
  margin-right: 60px;
  width: 150px;
  padding-top: 50px;
  height: 100px;
  justify-self: center;
  align-items: center;
  text-align: center;
  overflow-y: hidden;
}
.section3pricing-colus {
  margin-top: 40px;
  margin-left: 60px;
  font-size: 22px;
  margin-right: 60px;
  box-shadow: rgba(100, 100, 111, 0.4) 0px 7px 29px 0px;
  overflow-y: hidden;
}

.section3pricing-coluslist {
  display: flex;
  color: white;
  background-color: rgba(111, 0, 255, 255);
  font-weight: 900;
  margin-bottom: -1px;
  overflow-y: hidden;
  font-size: 17px;
  align-items: center;
  justify-content: center;
}

.section3pricing-logo {
  width: 130px;
  height: 30px;
  margin-right: 20px;
  z-index: 100000000000;
}

.section3pricing-coluslist li {
  margin-right: 60px;
  width: 150px;
  padding-top: 55px;
  height: 200px;
  justify-self: center;
  align-self: center;
  text-align: center;
  overflow-y: hidden;
}

.section3pricing-us1 {
  margin-top: -40px;
}
.section3pricing-us2 {
  margin-bottom: -30px;
}

.section3pricing-us4 {
  margin-bottom: -50px;
}

.section3pricing-col2 {
  margin-top: 80px;
  margin-left: 50px;
  font-size: 18px;
  font-weight: 900;
  /* box-shadow: rgba(100, 100, 111, 0.4) 0px 7px 29px 0px; */
  overflow-y: hidden;
}

.section3pricing-col2list {
  display: flex;
  padding-top: 60px;
  margin-bottom: -30px;
}

.section3pricing-col2list li {
  margin-right: 28px;
  width: 150px;
  height: 60px;
  justify-self: center;
  align-items: center;
  text-align: center;
}

.section3pricing-graphic {
  position: absolute;
  right: 5%;
  width: 100px;
  height: 250px;
  margin-top: -950px;
  background-image: url("../img/tinygraphic1.jpg");
  background-size: 100px;
  background-repeat: no-repeat;
  /* animation: blowing 10s ease-in-out infinite; */
}

/* Section 4 Pricing */
.section4pricing-background {
  background-color: rgb(248, 248, 248);
  /* padding-top: 75px; */
  padding-bottom: 75px;
  margin-top: 75px;
}

.section4pricing-description {
  margin-bottom: 0px;
  padding-bottom: 0px;
}

.section4pricing-paintbrushimg {
  height: 50px;
  width: 50px;
  margin-bottom: 15px;
  background-color: black;
  padding: 10px;
  border-radius: 5px;
}

.section4pricing-lockimg {
  height: 50px;
  width: 40px;
  margin-bottom: 15px;
  background-color: black;
  padding: 13px 10px;
  border-radius: 5px;
}

.section4pricing-paintbrushimg:hover {
  top: -10px;
  opacity: 0.8;
}

.section4pricing-toprow {
  height: 300px;
  margin-left: 100px;
  display: flex;
  padding-top: 50px;
  padding-left: 50px;
  overflow-y: hidden;
}

.section4pricing-container {
  margin-top: -10px;
  padding-top: 0px;
  /* margin-left: 100x; */
  margin-left: -50px;
  margin-right: 100px;
  display: flex;
  flex-direction: column;
}

.section4pricing-background {
  background-color: #fff;
}

.section4pricing-title {
  color: black;
  width: 60%;
  font-size: 55px;
  font-weight: 900;
  margin-left: 100px;
  margin-bottom: 60px;
  /* text-align: center; */
  padding-top: 50px;
}

.section4pricing-toprow ul {
  list-style: none;
}

.section4pricing-card {
  /* background-color: #ffffff; */
  height: 100%;
  /* padding: 50px 20px; */
  margin-right: 50px;
  margin-bottom: 50px;
  width: 1000px;
  /* box-shadow: rgba(100, 100, 111, 0.4) 0px 7px 29px 0px; */
  position: relative;
  top: 0;
  /* transition: right 2s ease; */
  overflow-y: hidden;
  transition: background-color ease 1s, top ease 0.5s;
  /* transition: top ease 0.5s; */
}

.section4pricing-card:hover {
  /* top: -20px; */
  /* background-color: rgba(0, 86, 36, 0.3); */
  /* right: -20px; */
}

.section4pricing-service {
  font-weight: 900;
  font-size: 25px;
}

.section4pricing-list {
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  justify-content: center;
  /* text-align: center; */
  margin-left: -20px;
  font-size: 17px;
}

/* FAQ */

.faq-title {
  color: black;
  width: 60%;
  font-size: 55px;
  font-weight: 900;
  margin-left: 300px;
  text-align: center;
  padding-top: 50px;
  margin-top: 50px;
}

.faq-container {
  width: 50%;
  margin-left: 350px;
  /* margin-top: 120px; */
}

.faq-background {
}

.faq-item {
  transition: all 1s ease-in-out;
}

.faq-header {
  text-align: center;
  padding: 0;
}

.faq-graphic {
  position: absolute;
  left: 0;
  width: 400px;
  height: 750px;
  margin-top: 100px;
  opacity: 0.2;
  background-image: url("../img/tinygraphic1.jpg");
  background-size: 400px;
  background-repeat: no-repeat;
  /* animation: blowing 10s ease-in-out infinite; */
}

.faq-whiteline {
  left: 0;
  position: absolute;
  z-index: 1000000000000;
  width: 8px;
  background-color: white;
  height: 681px;
  margin-top: -531px;
}

/* Section 2 Process */

.line {
  position: fixed;
  top: 10%;
  /* left: 50%; */
  width: 8px;
  height: 100%;
  background-color: #6f00ff;
  z-index: -1000000;
}

.section2process-container {
  height: 100%;
}

.section2process-section {
  border-bottom: 1px solid rgb(216, 216, 216);
  margin-left: 75px;
  margin-right: 75px;
}

.section2process-title {
  color: black;
  font-size: 50px;
  font-weight: 900;
  text-align: center;
  padding-top: 250px;
  margin-left: 50px;
  margin-right: 50px;
}

.section2process-contentcontainer {
  width: 50%;
  margin-right: 230px;
}

.section2process-contentcontainer2 {
  width: 50%;
}

.section2process-strategy-text {
  display: flex;
  /* margin-left: 350px; */
  margin-top: 100px;
}

.section2process-img {
  width: 620px;
  height: 430px;
}

.section2process-img2 {
  width: 620px;
  height: 430px;
  margin-right: 230px;
}

/* Contact */
.contact-background {
  background-color: #f7f6f6;
  height: 100vh;
}
.contact-container {
  height: 100vh;
  width: 100vw;
  /* margin-left: 250px; */
  /* margin-right: 250px; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  justify-items: center;
}

.contact-form {
  width: 45%;
  background-color: white;
  border-radius: 15px;
  /* padding: 15px 15px; */
  box-shadow: rgba(100, 100, 111, 0.4) 0px 7px 29px 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  justify-items: center;
}

.contact-emailinput {
  width: 100%;
  border-radius: 0px;
  border: 0px solid black;
  background-color: rgb(244, 244, 244);
  padding: 15px 10px;
  margin-right: 20px;
  margin-top: 20px;
  border-radius: 5px;
  border: 1px solid rgb(161, 161, 161);
}

.contact-emailinput::placeholder {
  color: rgb(121, 121, 121);
}

.contact-textinput {
  width: 62.7%;
  border-radius: 0px;
  border: 0px solid black;
  background-color: rgba(111, 0, 255, 1);
  padding: 15px 10px;
  margin-top: 15px;
}

.section6-textinput::placeholder {
  color: rgb(255, 255, 255);
}

.contact-title {
  font-size: 60px;
  font-weight: 900;
  width: 50%;
  text-align: center;
}

.contact-subtitle {
  font-size: 30px;
  width: 50%;
  text-align: center;
  margin-bottom: 30px;
  margin-top: 10px;
}

.contact-btn {
  margin-top: 30px;
  padding: 12px 30px;
  font-weight: bold;
  color: white;
  font-size: 20px;
  width: 90%;
  border: none;
  margin-bottom: 25px;
  margin-right: 15px;
  transition: background-color 0.3s ease-in-out;
  background-color: rgba(111, 0, 255, 255);
  border-radius: 5px;
}

.contact-btn:hover {
  background-color: rgb(58, 0, 133);
  cursor: pointer;
}

.contact-flex {
  display: flex;
  width: 90%;
}

/* Section 2 Portfolio */

.section2portfolio-container {
  /* margin-top: 20px; */
  padding-top: 20px;
  /* margin-left: 100x; */
  margin-left: -50px;
  margin-right: 150px;
  display: flex;
  flex-direction: column;
}

.section2portfolio-title {
  color: black;
  font-size: 50px;
  font-weight: 900;
  text-align: center;
  padding-top: 240px;
}

.section2portfolio-card {
  background-color: #ffffff;
  margin-right: 50px;
  margin-bottom: 30px;
  /* border: 3px solid rgba(111, 0, 255, 1); */
  border-radius: 15px;
  width: 100%;
  height: 250px;
  box-shadow: rgba(100, 100, 111, 0.4) 0px 7px 29px 0px;
  position: relative;
  top: 0;
  transition: top ease 0.5s;
  overflow-x: hidden;
  overflow-y: hidden;
}

.section2portfolio-cardtextcontainer {
  display: flex;
  flex-direction: column;
  margin-right: 20px;
  width: 33%;
  text-align: center;
  margin-top: -30px;
  margin-bottom: 180px;
}

.section2portfolio-img {
  width: 100%;
  height: 100%;
  transition: transform 0.8s ease;
  overflow-x: hidden;
  overflow-y: hidden;
}

.section2portfolio-img:hover {
  opacity: 0.6;
  transform: scale(1.1);
}

.section2portfolio-btn {
  display: none;
}

.section2portfolio-card:hover button {
  display: block;
  position: absolute;
  top: 0;
  text-align: center;
  padding: 10px 15px;
  font-weight: bold;
  border: none;
  background-color: rgb(100, 0, 230);
  color: white;
  border-radius: 5px;
  margin-top: 110px;
  margin-left: 120px;
}

.section2portfolio-card:hover {
  background-color: #ccc;
}

.section2portfolio-bush {
  position: absolute;
  right: 0;
  width: 150px;
  height: 500px;
  margin-top: 50px;
}
.section2portfolio-bush2 {
  position: absolute;
  rotate: 70deg;
  left: 0;
  width: 250px;
  height: 300px;
  margin-top: 350px;
}

/* Section 2 Project 1 */
.section2project1-container {
  display: flex;
  width: 90%;
  margin: auto;
  margin-top: 270px;
}

.section2project1-textcontainer {
  width: 80%;
  margin-right: 150px;
}

.section2project1-sitephoto {
  height: 3000px;
  width: 500px;
  border-radius: 15px;
  margin-right: 450px;
  margin-bottom: 150px;

  box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px,
    rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px,
    rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
}

.section2project1-section {
  margin-bottom: 45px;
}

.section2project1-section h1 {
  font-weight: 900;
}

.section2project1-section p {
  font-size: 17px;
}

.section2project1-graphic {
  position: absolute;
  left: 0%;
  width: 500px;
  height: 600px;
  margin-top: -1100px;
  background-image: url("../img/portfoliographic.jpg");
  background-size: 600px;
  background-repeat: no-repeat;
  /* animation: blowing 10s ease-in-out infinite; */
}

.section2project1-graphic2 {
  position: absolute;
  left: 0%;
  width: 500px;
  height: 600px;
  margin-top: -720px;
  background-image: url("../img/portfoliographic.jpg");
  background-size: 600px;
  background-repeat: no-repeat;
  /* animation: blowing 10s ease-in-out infinite; */
}

/* Reviews */
.reviews-container {
  display: flex;
  flex-direction: column;
  width: 90%;
  margin: auto;
}
.reviews-section {
  display: flex;
  margin-bottom: 35px;
}

.reviews-title {
  font-size: 75px;
  font-weight: 900;
  margin-bottom: 90px;
  margin-top: 100px;
  text-align: center;
  z-index: 100;
}

.reviews-subtitle {
  font-size: 20px;
  margin-top: 25px;
  margin-bottom: 40px;
  text-align: center;
}

.reviews-card {
  padding: 35px 35px;
  margin-left: 35px;
  /* background-color: rgba(5, 127, 193, 0.4); */
  background-color: white;
  border: solid 8px rgba(111, 0, 255, 1);
  width: 400px;
  box-shadow: rgba(0, 0, 0, 0.8) 0px 5px 15px;
  margin-bottom: 75px;
  margin-right: 40px;
  z-index: 100;
}

.reviews-card2 {
  padding: 35px 35px;
  margin-bottom: 75px;
  border: solid 8px rgba(111, 0, 255, 1);
  box-shadow: rgba(0, 0, 0, 0.8) 0px 5px 15px;
  /* background-color: rgba(5, 127, 193, 0.4); */
  background-color: white;
  width: 400px;
  /* margin-left: 500px; */
  /* margin-top: -200px; */
}

.reviews-card3 {
  padding: 35px 35px;
  border: solid 8px rgba(111, 0, 255, 1);
  box-shadow: rgba(0, 0, 0, 0.8) 0px 5px 15px;
  margin-left: 35px;
  margin-bottom: 75px;
  /* background-color: rgba(5, 127, 193, 0.4); */
  background-color: white;
  width: 400px;
  /* margin-left: 950px; */
  /* margin-top: -200px; */
}

.reviews-card4 {
  padding: 35px 35px;
  margin-left: 35px;
  margin-bottom: 75px;
  border-radius: 10px;
  /* background-color: rgba(5, 127, 193, 0.4); */
  background-color: white;
  width: 550px;
  margin-left: 950px;
  margin-bottom: 800px;
  margin-top: -890px;
  box-shadow: rgba(0, 0, 0, 0.8) 0px 5px 15px;
}
.reviews-card5 {
  padding: 35px 35px;
  margin-left: 35px;
  margin-bottom: 75px;
  border-radius: 10px;
  /* background-color: rgba(5, 127, 193, 0.4); */
  background-color: white;
  width: 550px;
  margin-left: 10px;
  /* margin-bottom: 800px; */
  margin-top: -560px;
  box-shadow: rgba(0, 0, 0, 0.8) 0px 5px 15px;
}

.reviews-reviewcontainer {
  display: flex;
}

.reviews-textcontainer {
  width: 100%;
}

.reviews-name {
  font-weight: 700;
  font-size: 17px;
  margin-bottom: 25px;
  margin-left: 9px;
  margin-bottom: -0px;
}

.reviews-name2 {
  font-weight: 300;
  font-size: 17px;
  margin-left: 9px;
  font-style: italic;
  margin-bottom: -25px;
}

.reviews-reviewcontainer img {
  height: 70px;
  width: 70px;
  border-radius: 50%;
  margin-top: 18px;
  /* border: 3px solid rgba(5, 127, 193, 1); */
}

.reviews-innerreviewcontainer {
  margin-left: 10px;
  margin-top: 15px;
}

.reviews-innerreviewcontainer img {
  height: 50px !important;
  width: 170px !important;
  border: none;
}

/* Onboarding */
.onboarding-form {
  width: 50%;
  height: 380px;
  /* background-color: white; */
  background: linear-gradient(
    to bottom,
    #ffffff 0%,
    #ffffff 80%,
    rgba(111, 0, 255, 1) 80%,
    rgba(111, 0, 255, 1) 100%
  );
  border-radius: 15px;
  padding: 15px 15px;
  box-shadow: rgba(100, 100, 111, 0.4) 0px 7px 29px 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  justify-items: center;
  margin: auto;
  margin-bottom: 45px;
  margin-top: 30px;
}

.onboarding-questioncontainer {
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  align-items: center;
  height: 100%;
}

.onboarding-globaltitle {
  text-align: center;
  font-size: 55px;
  font-weight: 900;
  width: 70%;
  margin: auto;
  margin-top: 25px;
}

.onboarding-title {
  text-align: center;
  font-size: 25px;
  font-weight: 900;
  overflow-y: hidden;
}

.onboarding-textoption1 {
  margin-right: 15px;
  margin-bottom: 15px;
  border-radius: 5px;
  border: 1px solid black;
  padding: 15px;
  width: 220px;
  overflow-y: hidden;
}

.onboarding-textoption1:hover {
  cursor: pointer;
  background-color: rgb(248, 248, 248);
}

.onboarding-textoption2 {
  margin-bottom: 15px;
  border-radius: 5px;
  border: 1px solid black;
  padding: 15px;
  width: 220px;
  overflow-y: hidden;
}

.onboarding-textoption2:hover {
  cursor: pointer;
  background-color: rgb(248, 248, 248);
}
.onboarding-textoption3 {
  margin-right: 15px;
  margin-bottom: 15px;
  border-radius: 5px;
  border: 1px solid black;
  padding: 15px;
  width: 220px;
  overflow-y: hidden;
}

.onboarding-textoption3:hover {
  cursor: pointer;
  background-color: rgb(248, 248, 248);
}

.onboarding-textoption4 {
  margin-bottom: 15px;
  border-radius: 5px;
  border: 1px solid black;
  padding: 15px;
  width: 220px;
  overflow-y: hidden;
}

.onboarding-textoption4:hover {
  cursor: pointer;
  background-color: rgb(248, 248, 248);
}

.onboarding-pagediv {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.onboarding-next {
  font-size: 23px;
  font-weight: bold;
  color: white;
  overflow-y: hidden;
}

.onboarding-prev {
  font-size: 23px;
  font-weight: bold;
  color: white;
  overflow-y: hidden;
}

.onboarding-prev:hover {
  cursor: pointer;
}

.onboarding-next:hover {
  cursor: pointer;
}

.onboarding-arrow1 {
  width: 50px;
  height: 20px;
  margin-top: 8px;
  margin-left: 7px;
}

.onboarding-arrow2 {
  width: 50px;
  height: 20px;
  margin-top: 8px;
  margin-right: 7px;
}

.onboarding-arrow2:hover {
  cursor: pointer;
}

.onboarding-arrow1:hover {
  cursor: pointer;
}

.onboarding-preview {
  height: 500px;
  width: 130px;
  margin-right: 25px;
}

.onboarding-getstarted {
  margin-top: 30px;
  padding: 12px 70px;
  font-weight: bold;
  color: white;
  font-size: 20px;
  border: none;
  margin-bottom: 80px;
  margin-right: 15px;
  transition: background-color 0.3s ease-in-out;
  background-color: rgb(38, 0, 87);
  border-radius: 5px;
}

.onboarding-getstarted:hover {
  cursor: pointer;
}

.onboarding-googledoc {
  text-decoration: none;
  color: black;
  font-size: 25px;
  font-weight: bold;
  margin-right: 25px;
}

.onboarding-uploadimg {
  width: 32px;
  height: 32px;
  margin-right: 4px;
}

.onboarding-pricing {
  color: rgb(82, 82, 82);
  font-weight: 900;
  /* background-color: #6f00ff; */
  padding: 15px;
  width: 100%;
  border-radius: 5px;
  margin-top: 20px;
  text-align: center;
}

.onboarding-pricing:hover {
  color: rgb(27, 27, 27);
}

.onboarding-nostyle {
  text-decoration: none;
  overflow-y: hidden;
}

.onboarding-agreement {
  color: rgb(82, 82, 82);
  font-weight: 900;
  /* background-color: #6f00ff; */
  padding: 15px;
  width: 100%;
  border-radius: 5px;
  text-align: center;
  overflow-y: hidden;
  overflow-x: hidden;
  margin-top: -15px;
}

.onboarding-agreement:hover {
  color: rgb(27, 27, 27);
}

.onboarding-layoutnum {
  padding: 0;
  margin: 0;
  margin-left: 3px;
  margin-right: 5px;
  font-size: 23px;
  font-weight: 900;
}

.onboarding-hiddenanswers {
  z-index: -100;
}

@media (max-width: 1480px) {
  .nav-logo {
    margin-left: 130px;
  }
}
@media (max-width: 1460px) {
  .nav-logo {
    margin-left: 110px;
  }
}
@media (max-width: 1440px) {
  .nav-logo {
    margin-left: 90px;
  }
}
@media (max-width: 1420px) {
  .nav-logo {
    margin-left: 70px;
  }
}
@media (max-width: 1400px) {
  .nav-logo {
    margin-left: 50px;
  }
}
@media (max-width: 1380px) {
  .nav-logo {
    margin-left: 30px;
  }
}
@media (max-width: 1360px) {
  .nav-logo {
    margin-left: 10px;
  }
}

@media (max-width: 1350px) {
  .nav-logo {
    /* margin-left: 150px; */
  }

  .nav-container {
    display: flex;
    justify-content: space-between;
    width: 1200px;
    /* margin-top: 25px; */
  }
}

@media (max-width: 1400px) {
  .plumber-bg {
    margin-left: -100px;
  }
}
@media (max-width: 1300px) {
  .plumber-bg {
    margin-left: -200px;
  }
}
@media (max-width: 1200px) {
  .plumber-bg {
    margin-left: -300px;
  }
}
@media (max-width: 1100px) {
  .plumber-bg {
    margin-left: -400px;
  }
}

@media (max-width: 1000px) {
  .plumber-bg {
    margin-left: -500px;
  }
}

@media (max-width: 1350px) {
  /* Mobile Section 5 1250 */
  .section5-strategy-text {
    display: flex;
    margin-left: 150px;
    margin-top: 35px;
  }

  .section5-contentcontainer {
    width: 50%;
    margin-left: 100px;
  }

  .section5-strategy-text-hidden {
    display: none;
  }
  .section5-design-text {
    display: flex;
    margin-left: 150px;
    margin-top: 35px;
  }

  .section5-design-text-hidden {
    display: none;
  }
  .section5-development-text {
    display: flex;
    margin-left: 150px;
    margin-top: 35px;
  }

  .section5-development-text-hidden {
    display: none;
  }
  .section5-maintenance-text {
    display: flex;
    margin-left: 150px;
    margin-top: 35px;
  }

  .section5-maintenance-text-hidden {
    display: none;
  }
  .section5-launch-text {
    display: flex;
    margin-left: 150px;
    margin-top: 35px;
  }

  .section5-launch-text-hidden {
    display: none;
  }
  .section5-maintain-text {
    display: flex;
    margin-left: 150px;
    margin-top: 35px;
  }

  /* Mobile Section 2 1350 */
  .section2-toprow {
    margin-left: 70px;
    display: flex;
    padding-top: 50px;
    padding-left: 50px;
  }
}

@media (max-width: 1090px) {
  .styledBurger {
    width: 2.8rem;
    height: 2rem;
    margin-right: 0px;
    position: fixed;
    top: 15px;
    right: 20px;
    z-index: 1000000;
    overflow-x: hidden;
    overflow-y: hidden;
  }
  .burger {
    width: 2.5rem;
    height: 0.25rem;
    /* background-color: #333; */
    margin-bottom: 10px;
    border-radius: 10px;
    transform-origin: 1px;
    transition: all 0.3s linear;
    z-index: 100000;
    overflow-x: hidden;
    overflow-y: hidden;
  }
}

@media (max-width: 1480px) {
  /* Mobile Home 1480 */
  .home-container {
    margin-left: 75px;
    margin-right: 75px;
  }
}

@media (max-width: 1300px) {
  /* Mobile Section 2 1300*/
  .section2-card {
    height: 350px;
    overflow-y: hidden;
  }
  .section2-paintbrushimg {
    height: 65px;
    width: 65px;
    margin-bottom: 15px;
  }
  .section2-lockimg {
    height: 65px;
    width: 50px;
    margin-bottom: 15px;
  }

  .section2-title {
    color: black;
    font-size: 40px;
    font-weight: 900;
    text-align: center;
    padding-top: 50px;
  }

  .section2-subtitle {
    font-size: 15px;
    font-weight: lighter;
    text-align: center;
  }

  .section2-service {
    font-weight: 900;
    font-size: 19px;
  }

  .section2-list {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    margin-left: -20px;
    font-size: 15px;
  }
}

@media (max-width: 1090px) {
  /* Mobile Section 2 1090 */
  .section2-container {
    width: 100%;
    margin-right: 0px;

    /* margin-right: -55px; */
  }

  .section2-card {
    width: 90%;
  }
  .section2-toprow {
    margin-left: 65px;
    width: 100%;
    display: flex;
    flex-direction: column;
    padding-top: 50px;

    /* padding-right: 50px; */
  }

  /* Mobile Nav 2 1090 */
  .nav-logo {
    margin-left: 25px;
  }

  .nav-container {
    margin-top: -8px;
  }

  .nav-lidiv {
    flex-direction: column;
  }

  .sticky {
    flex-direction: column;
  }

  /* Mobile Home 1090 */
  .home-container {
    margin-left: 25px;
    margin-right: 25px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    overflow-x: hidden;
  }

  .home-firstline {
    font-size: 35px;
    margin-top: 60px;
    font-weight: bold;
    width: 100%;
    color: white;
    text-align: center;
  }

  .home-secondline {
    font-size: 55px;
    margin-top: -25px;
    font-weight: bold;
    width: 100%;
    color: white;
    text-align: center;
  }

  .home-leftcontainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .home-checkmark {
    height: 20px;
    width: 20px;
    margin-right: 10px;
  }

  .home-listdiv {
    display: flex;
    text-align: center;
    justify-content: center;
  }

  .home-listul {
    padding-left: 0px;
    font-size: 18px;
    margin-top: 25px;
    color: white;
  }

  .home-button {
    margin-top: 30px;
    padding: 12px 50px;
    font-weight: bold;
    color: white;
    font-size: 18px;
    border: none;
    margin-bottom: 80px;
    margin-right: 15px;
    transition: background-color 0.3s ease-in-out;
    background-color: rgb(38, 0, 87);
    border-radius: 5px;
  }

  .home-button:hover {
    background-color: rgb(76, 0, 175);
    cursor: pointer;
  }

  .home-rightcontainer {
    margin-top: 70px;
    overflow-x: hidden;
    width: 100%;
  }

  .home-placeholdervideo {
    border-radius: 10px;
    height: 400px;
    width: 100%;
    background-color: black;
  }

  /* Mobile Section 4 1090 */
  .section4-container {
    /* margin-top: 20px; */
    padding-top: 20px;
    /* margin-left: 100x; */
    margin-left: -50px;
    margin-right: 50px;
    display: flex;
    flex-direction: column;
  }

  .section4-title {
    color: black;
    font-size: 50px;
    font-weight: 900;
    text-align: center;
    padding-top: 130px;
  }

  .section4-card {
    background-color: #ffffff;
    margin-right: 50px;
    margin-bottom: 50px;
    border: 2px solid rgba(111, 0, 255, 1);
    width: 100%;
    box-shadow: rgba(100, 100, 111, 0.4) 0px 7px 29px 0px;
    position: relative;
    top: 0;
    transition: top ease 0.5s;
    overflow-x: hidden;
    overflow-y: hidden;
  }

  .section4-cardtextcontainer {
    display: flex;
    flex-direction: column;
    margin-right: 20px;
    width: 100%;
    height: 500px;
    text-align: center;
    margin-top: -30px;
  }

  .section4-img {
    width: 100%;
    height: 100%;
    transition: transform 0.8s ease;
    overflow-x: hidden;
    overflow-y: hidden;
  }

  .section4-img:hover {
    opacity: 0.6;
    transform: scale(1.1);
  }

  .section4-btn {
    display: none;
  }

  .section4-card:hover button {
    display: block;
    position: absolute;
    top: 0;
    text-align: center;
    padding: 10px 15px;
    font-weight: bold;
    border: none;
    background-color: rgb(100, 0, 230);
    color: white;
    border-radius: 5px;
    margin-top: 110px;
    margin-left: 120px;
  }

  .section4-card:hover {
    background-color: #ccc;
  }

  /* Mobile Section 5 1090 */
  .section5-background {
    background-color: #ffffff;
  }

  .section5-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 170px;
  }

  .section5-title {
    font-size: 50px;
    font-weight: bold;
    overflow-y: hidden;
    overflow-x: hidden;
  }

  .section5-subtitle {
    font-size: 17px;
    text-align: center;
    width: 50%;
    margin-top: 5px;
    margin-bottom: 50px;
    overflow-y: hidden;
    overflow-wrap: hidden;
  }

  .section5-strategybtn {
    padding: 10px 15px;
    font-size: 15px;
    font-weight: bolder;
    color: black;
    border: 1px solid #ccc;
    background-color: rgb(255, 255, 255);
    transition: background-color 0.3s ease-in-out;
  }

  .section5-strategybtn:focus {
    background-color: rgba(111, 0, 255, 255);
    border: solid 3px black;
  }

  .section5-strategybtn:hover {
    background-color: rgba(111, 0, 255, 255);
  }
  .section5-designbtn {
    padding: 10px 15px;
    font-size: 15px;
    border: 1px solid #ccc;
    background-color: rgb(255, 255, 255);
    font-weight: bolder;
    color: black;
    transition: background-color 0.3s ease-in-out;
  }

  .section5-designbtn:focus {
    background-color: rgba(111, 0, 255, 255);
    border: solid 3px black;
  }

  .section5-designbtn:hover {
    background-color: rgba(111, 0, 255, 255);
  }
  .section5-developmentbtn {
    padding: 10px 15px;
    font-size: 15px;
    border: 1px solid #ccc;
    background-color: rgb(255, 255, 255);
    font-weight: bolder;
    color: black;
    transition: background-color 0.3s ease-in-out;
  }

  .section5-developmentbtn:focus {
    background-color: rgba(111, 0, 255, 255);
    border: solid 3px black;
  }

  .section5-developmentbtn:hover {
    background-color: rgba(111, 0, 255, 255);
  }
  .section5-maintenancebtn {
    padding: 10px 15px;
    font-size: 15px;
    border: 1px solid #ccc;
    background-color: rgb(255, 255, 255);

    font-weight: bolder;
    color: black;
    transition: background-color 0.3s ease-in-out;
  }

  .section5-maintenancebtn:focus {
    background-color: rgba(111, 0, 255, 255);
    border: solid 3px black;
  }

  .section5-maintenancebtn:hover {
    background-color: rgba(111, 0, 255, 255);
  }
  .section5-launchbtn {
    padding: 10px 15px;
    font-size: 15px;
    border: 1px solid #ccc;
    background-color: rgb(255, 255, 255);

    font-weight: bolder;
    color: black;
    transition: background-color 0.3s ease-in-out;
  }

  .section5-launchbtn:focus {
    background-color: rgba(111, 0, 255, 255);
    border: solid 3px black;
  }

  .section5-launchbtn:hover {
    background-color: rgba(111, 0, 255, 255);
  }
  .section5-maintainbtn {
    padding: 10px 15px;
    font-size: 15px;
    border: 1px solid #ccc;
    background-color: rgb(255, 255, 255);

    font-weight: bolder;
    color: black;
    transition: background-color 0.3s ease-in-out;
  }

  .section5-maintainbtn:focus {
    background-color: rgba(111, 0, 255, 255);
    border: solid 3px black;
  }

  .section5-maintainbtn:hover {
    background-color: rgba(111, 0, 255, 255);
  }

  .section5-strategy-text {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 0px;
    margin-top: 35px;
  }

  .section5-strategy-text-hidden {
    display: none;
  }
  .section5-design-text {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 0px;
    margin-top: 35px;
  }

  .section5-design-text-hidden {
    display: none;
  }
  .section5-development-text {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 0px;
    margin-top: 35px;
  }

  .section5-development-text-hidden {
    display: none;
  }
  .section5-maintenance-text {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 0px;
    margin-top: 35px;
  }

  .section5-maintenance-text-hidden {
    display: none;
  }
  .section5-launch-text {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 0px;
    margin-top: 35px;
  }

  .section5-launch-text-hidden {
    display: none;
  }
  .section5-maintain-text {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 0px;
    margin-top: 35px;
  }

  .section5-maintain-text-hidden {
    display: none;
  }

  .section5-contentcontainer {
    width: 40%;
    margin-left: 100px;
  }

  .section5-img {
    width: 250px;
    height: 230px;
  }

  .section5-contenttitle {
    font-size: 35px;
    font-weight: 900;
    color: black;
  }
  /* Mobile Section 6 1090 */
  .section6-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .section6-contactcta {
    width: 100%;
    height: 170px;
    margin-left: 25px;
    margin-right: 25px;
    margin-top: 175px;
    text-align: center;
    overflow-y: hidden;
  }

  .section6-form {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-right: 5px;
    margin-left: 80px;
    /* height: 1000px; */
  }

  .section6-emailinput {
    width: 40%;
    border-radius: 0px;
    border: 0px solid black;
    background-color: rgb(52, 0, 121);
    padding: 15px 10px;
    border-radius: 5px;
    margin-right: 15px;
    margin-top: 15px;
  }
  .section6-boldtext {
  }

  /* Mobile Section 7 1090 */
  .section7-background {
    background-color: rgb(29, 0, 66);
  }

  .section7-servicecontainer {
    display: flex;
    font-size: 13px;
  }

  .section7-container {
    height: 285px;
    width: 100%;
    margin-left: 15px;
    margin-top: 75px;
    display: flex;
    overflow-x: hidden;
  }

  .section7-sections {
    width: 20%;
    margin-right: 75px;
  }

  .section7-logo {
    width: 130px;
    height: 30px;
  }

  .section7-about {
    color: white;
    font-weight: lighter;
    margin-top: 55px;
  }

  .section7-nav {
    color: white;
    font-size: 18px;
  }

  .section7-title {
    color: white;
    font-size: 18px;
  }
  .section7-subtext {
    font-weight: lighter;
    margin-right: 25px;
  }

  /* Mobile Section 2 Pricing 1090 */
  .home-button2 {
    font-size: 15px;
    padding: 12px 40px;
  }

  /* Mobile Section 4 Pricing 1090 */
  .section4pricing-container {
    margin-right: -25px;
    margin-left: -105px;
  }

  .section4pricing-toprow {
    flex-direction: column;
    height: 100%;
  }

  .section4pricing-description {
    width: 80vw;
  }

  .section4pricing-title {
    margin-left: 45px;
    width: 80vw;
  }

  /* Mobile Section 3 Pricing 1090 */
  .section3pricing-container {
    display: flex;
    flex-direction: column;
    /* justify-content: space-around; */
    margin-left: 50px;
    margin-right: 50px;
  }

  /* Mobile FAQ 1090 */
  .faq-container {
    width: 50%;
    margin-left: 300px;
    /* margin-top: 120px; */
  }

  /* Mobile Section 2 Process 1090 */

  .section2process-container {
    height: 100%;
  }

  .section2process-section {
    border-bottom: 1px solid rgb(216, 216, 216);
    margin-left: 25px;
    margin-right: 25px;
  }

  .section2process-title {
    color: black;
    font-size: 50px;
    font-weight: 900;
    text-align: center;
    padding-top: 150px;
    margin-left: 50px;
    margin-right: 50px;
  }

  .section2process-contentcontainer {
    width: 50%;
    margin-right: 120px;
  }

  .section2process-contentcontainer2 {
    width: 50%;
  }

  .section2process-strategy-text {
    display: flex;
    /* margin-left: 350px; */
    margin-top: 100px;
  }

  .section2process-img {
    width: 620px;
    height: 430px;
  }

  .section2process-img2 {
    width: 620px;
    height: 430px;
    margin-right: 120px;
  }

  /* Mobile Contact 1090 */
  /* Contact */
  .contact-background {
    background-color: #f7f6f6;
    height: 100vh;
  }
  .contact-container {
    height: 100vh;
    width: 100vw;
    /* margin-left: 250px; */
    /* margin-right: 250px; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    justify-items: center;
  }

  .contact-form {
    width: 90%;
    background-color: white;
    border-radius: 15px;
    /* padding: 15px 15px; */
    box-shadow: rgba(100, 100, 111, 0.4) 0px 7px 29px 0px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    justify-items: center;
  }

  .contact-emailinput {
    width: 100%;
    border-radius: 0px;
    border: 0px solid black;
    background-color: rgb(244, 244, 244);
    padding: 15px 10px;
    margin-right: 20px;
    margin-top: 20px;
    border-radius: 5px;
    border: 1px solid rgb(161, 161, 161);
  }

  .contact-emailinput::placeholder {
    color: rgb(121, 121, 121);
  }

  .contact-textinput {
    width: 62.7%;
    border-radius: 0px;
    border: 0px solid black;
    background-color: rgba(111, 0, 255, 1);
    padding: 15px 10px;
    margin-top: 15px;
  }

  .section6-textinput::placeholder {
    color: rgb(255, 255, 255);
  }

  .contact-title {
    font-size: 50px;
    font-weight: 900;
    width: 90%;
    text-align: center;
  }

  .contact-subtitle {
    font-size: 18px;
    width: 90%;
    text-align: center;
    margin-bottom: 30px;
    margin-top: 10px;
  }

  .contact-btn {
    margin-top: 30px;
    padding: 12px 30px;
    font-weight: bold;
    color: white;
    font-size: 20px;
    width: 90%;
    border: none;
    margin-bottom: 25px;
    margin-right: 15px;
    transition: background-color 0.3s ease-in-out;
    background-color: rgba(111, 0, 255, 255);
    border-radius: 5px;
  }

  .contact-btn:hover {
    background-color: rgb(58, 0, 133);
    cursor: pointer;
  }

  .contact-flex {
    display: flex;
    width: 90%;
  }

  /* Mobile Onboarding 1090 */
  .onboarding-form {
    width: 50%;
    height: 580px;
    /* background-color: white; */
    background: linear-gradient(
      to bottom,
      #ffffff 0%,
      #ffffff 87%,
      rgba(111, 0, 255, 1) 87%,
      rgba(111, 0, 255, 1) 100%
    );
    border-radius: 15px;
    padding: 15px 15px;
    box-shadow: rgba(100, 100, 111, 0.4) 0px 7px 29px 0px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    justify-items: center;
    margin: auto;
    margin-bottom: 45px;
    margin-top: 30px;
  }

  .onboarding-questioncontainer {
    display: flex;
    flex-direction: column;
    /* justify-content: center; */
    align-items: center;
    height: 100%;
  }

  .onboarding-globaltitle {
    text-align: center;
    font-size: 45px;
    font-weight: 900;
    width: 95%;
    margin: auto;
    margin-top: 25px;
  }

  .onboarding-title {
    text-align: center;
    font-size: 18px;
    font-weight: 900;
    overflow-y: hidden;
  }

  .onboarding-textoption1 {
    margin-right: 15px;
    margin-bottom: 15px;
    border-radius: 5px;
    border: 1px solid black;
    padding: 15px 15px 35px 15px;
    width: 200px;
    overflow-y: hidden;
  }

  .onboarding-textoption1:hover {
    cursor: pointer;
    background-color: rgb(248, 248, 248);
  }

  .onboarding-textoption2 {
    margin-bottom: 15px;
    border-radius: 5px;
    border: 1px solid black;
    padding: 15px 15px 35px 15px;
    width: 200px;
    overflow-y: hidden;
  }

  .onbording-sectioncontainer {
    flex-direction: column;
  }

  .onboarding-textoption2:hover {
    cursor: pointer;
    background-color: rgb(248, 248, 248);
  }

  .onboarding-textoption3 {
    margin-right: 15px;
    margin-bottom: 15px;
    border-radius: 5px;
    border: 1px solid black;
    padding: 15px;
    width: 280px;
    overflow-y: hidden;
  }

  .onboarding-textoption3:hover {
    cursor: pointer;
    background-color: rgb(248, 248, 248);
  }

  .onboarding-textoption4 {
    margin-bottom: 15px;
    border-radius: 5px;
    border: 1px solid black;
    padding: 15px;
    width: 280px;
    overflow-y: hidden;
  }

  .onboarding-textoption4:hover {
    cursor: pointer;
    background-color: rgb(248, 248, 248);
  }

  .onboarding-pagediv {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }

  .onboarding-next {
    font-size: 23px;
    font-weight: bold;
    color: white;
    overflow-y: hidden;
  }

  .onboarding-prev {
    font-size: 23px;
    font-weight: bold;
    color: white;
    overflow-y: hidden;
  }

  .onboarding-prev:hover {
    cursor: pointer;
  }

  .onboarding-next:hover {
    cursor: pointer;
  }

  .onboarding-arrow1 {
    width: 50px;
    height: 20px;
    margin-top: 8px;
    margin-left: 7px;
  }

  .onboarding-arrow2 {
    width: 50px;
    height: 20px;
    margin-top: 8px;
    margin-right: 7px;
  }

  .onboarding-arrow2:hover {
    cursor: pointer;
  }

  .onboarding-arrow1:hover {
    cursor: pointer;
  }

  .onboarding-preview {
    height: 500px;
    width: 130px;
    margin-right: 25px;
  }

  .onboarding-getstarted {
    margin-top: 130px;
    padding: 12px 70px;
    font-weight: bold;
    color: white;
    font-size: 20px;
    border: none;
    margin-bottom: 0px;
    margin-right: 15px;
    transition: background-color 0.3s ease-in-out;
    background-color: rgb(38, 0, 87);
    border-radius: 5px;
  }

  .onboarding-getstarted:hover {
    cursor: pointer;
  }

  .onboarding-googledoc {
    text-decoration: none;
    color: black;
    font-size: 25px;
    font-weight: bold;
    margin-right: 25px;
  }

  .onboarding-uploadimg {
    width: 32px;
    height: 32px;
    margin-right: 4px;
  }

  .onboarding-pricing {
    color: rgb(82, 82, 82);
    font-weight: 900;
    /* background-color: #6f00ff; */
    padding: 15px;
    width: 100%;
    border-radius: 5px;
    margin-top: 20px;
    text-align: center;
  }

  .onboarding-pricing:hover {
    color: rgb(27, 27, 27);
  }

  .onboarding-nostyle {
    text-decoration: none;
    overflow-y: hidden;
  }

  .onboarding-agreement {
    color: rgb(82, 82, 82);
    font-weight: 900;
    /* background-color: #6f00ff; */
    padding: 15px;
    width: 100%;
    border-radius: 5px;
    text-align: center;
    overflow-y: hidden;
    overflow-x: hidden;
    margin-top: -15px;
  }

  .onboarding-agreement:hover {
    color: rgb(27, 27, 27);
  }

  .onboarding-layoutnum {
    padding: 0;
    margin: 0;
    margin-left: 3px;
    margin-right: 5px;
    font-size: 23px;
    font-weight: 900;
  }

  .onboarding-hiddenanswers {
    z-index: -100;
  }

  /* Mobile Reviews 1090 */
  .reviews-container {
    display: flex;
    flex-direction: column;
    width: 95%;
    margin: auto;
  }
  .reviews-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-items: center;
    margin-bottom: 35px;
  }

  .reviews-title {
    font-size: 55px;
    font-weight: 900;
    margin-bottom: 0px;
    margin-top: 100px;
    text-align: center;
    z-index: 100;
  }

  .reviews-subtitle {
    font-size: 20px;
    margin-top: 25px;
    margin-bottom: 40px;
    text-align: center;
  }

  .reviews-card {
    padding: 35px 35px;
    margin-left: 15px;
    /* background-color: rgba(5, 127, 193, 0.4); */
    background-color: white;
    border: solid 8px rgba(111, 0, 255, 1);
    width: 90%;
    box-shadow: rgba(0, 0, 0, 0.8) 0px 5px 15px;
    margin-bottom: 75px;
    margin-right: 10px;
    z-index: 100;
    text-align: center;
  }

  .reviews-card h3 {
    text-align: center;
  }

  .reviews-card2 {
    padding: 35px 35px;
    margin-bottom: 75px;
    border: solid 8px rgba(111, 0, 255, 1);
    box-shadow: rgba(0, 0, 0, 0.8) 0px 5px 15px;
    /* background-color: rgba(5, 127, 193, 0.4); */
    background-color: white;
    width: 90%;
    /* margin-left: 500px; */
    /* margin-top: -200px; */
  }

  .reviews-card3 {
    padding: 35px 35px;
    border: solid 8px rgba(111, 0, 255, 1);
    box-shadow: rgba(0, 0, 0, 0.8) 0px 5px 15px;
    margin-left: 15px;
    margin-bottom: 75px;
    /* background-color: rgba(5, 127, 193, 0.4); */
    background-color: white;
    width: 90%;
    /* margin-left: 950px; */
    /* margin-top: -200px; */
  }

  .reviews-card4 {
    padding: 35px 35px;
    margin-left: 35px;
    margin-bottom: 75px;
    border-radius: 10px;
    /* background-color: rgba(5, 127, 193, 0.4); */
    background-color: white;
    width: 550px;
    margin-left: 950px;
    margin-bottom: 800px;
    margin-top: -890px;
    box-shadow: rgba(0, 0, 0, 0.8) 0px 5px 15px;
  }
  .reviews-card5 {
    padding: 35px 35px;
    margin-left: 35px;
    margin-bottom: 75px;
    border-radius: 10px;
    /* background-color: rgba(5, 127, 193, 0.4); */
    background-color: white;
    width: 550px;
    margin-left: 10px;
    /* margin-bottom: 800px; */
    margin-top: -560px;
    box-shadow: rgba(0, 0, 0, 0.8) 0px 5px 15px;
  }

  .reviews-reviewcontainer {
    display: flex;
  }

  .reviews-textcontainer {
    width: 100%;
  }

  .reviews-name {
    font-weight: 700;
    font-size: 17px;
    margin-bottom: 25px;
    margin-left: 9px;
    margin-bottom: -0px;
  }

  .reviews-name2 {
    font-weight: 300;
    font-size: 17px;
    margin-left: 9px;
    font-style: italic;
    margin-bottom: -25px;
  }

  .reviews-reviewcontainer img {
    height: 70px;
    width: 70px;
    border-radius: 50%;
    margin-top: 18px;
    /* border: 3px solid rgba(5, 127, 193, 1); */
  }

  .reviews-innerreviewcontainer {
    margin-left: 10px;
    margin-top: 15px;
  }

  .reviews-innerreviewcontainer img {
    height: 50px !important;
    width: 170px !important;
    border: none;
  }

  /* Mobile Section2Portfolio 1090 */

  /* .section2portfolio-card {
    background-color: #ffffff;
    margin-right: 50px;
    margin-bottom: 30px;
  border: 3px solid rgba(111, 0, 255, 1);
  border-radius: 15px;
    width: 100%;
    height: 250px;
    box-shadow: rgba(100, 100, 111, 0.4) 0px 7px 29px 0px;
    position: relative;
    top: 0;
    transition: top ease 0.5s;
    overflow-x: hidden;
    overflow-y: hidden;
  }

  .section2portfolio-cardtextcontainer {
    display: flex;
    flex-direction: column;
    margin-right: 0px;
    width: 100%;
    text-align: center;
    margin-top: -30px;
    margin-bottom: 40px;
  } */

  .section2portfolio-container {
    margin-top: 20px;
    padding-top: 20px;
    margin-left: 100x;
    margin-left: -50px;
    margin-right: 50px;
    display: flex;
    flex-direction: column;
  }

  /* .section2portfolio-container {
    margin-top: -10px;
    padding-top: 0px; */
  /* margin-left: 100x; */
  /* margin-left: 0px;
    margin-right: 0px;
    width: 100%;
    display: flex;
    flex-direction: column;
  } */

  .section2portfolio-title {
    color: black;
    font-size: 50px;
    font-weight: 900;
    text-align: center;
    padding-top: 130px;
  }

  .section2portfolio-card {
    background-color: #ffffff;
    margin-right: 50px;
    margin-bottom: 50px;
    border: 2px solid rgba(111, 0, 255, 1);
    width: 100%;
    box-shadow: rgba(100, 100, 111, 0.4) 0px 7px 29px 0px;
    position: relative;
    top: 0;
    transition: top ease 0.5s;
    overflow-x: hidden;
    overflow-y: hidden;
  }

  .section2portfolio-cardtextcontainer {
    display: flex;
    flex-direction: column;
    margin-right: 20px;
    width: 100%;
    height: 500px;
    text-align: center;
    margin-top: -30px;
    margin-bottom: -110px;
  }

  .section2portfolio-img {
    width: 100%;
    height: 100%;
    transition: transform 0.8s ease;
    overflow-x: hidden;
    overflow-y: hidden;
  }

  .section2portfolio-img:hover {
    opacity: 0.6;
    transform: scale(1.1);
  }

  .section2portfolio-btn {
    display: none;
  }

  .section2portfolio-card:hover button {
    display: block;
    position: absolute;
    top: 0;
    text-align: center;
    padding: 10px 15px;
    font-weight: bold;
    border: none;
    background-color: rgb(100, 0, 230);
    color: white;
    border-radius: 5px;
    margin-top: 110px;
    margin-left: 120px;
  }

  .section2portfolio-card:hover {
    background-color: #ccc;
  }

  /* Mobile Section 2 Project 1 1090 */
  .section2project1-container {
    display: flex;
    flex-direction: column-reverse;
    width: 90%;
    margin: auto;
    margin-top: 150px;
  }

  .section2project1-textcontainer {
    width: 100%;
    margin-right: 150px;
    margin-top: 80px;
    margin-bottom: 50px;
  }

  .section2project1-sitephoto {
    height: 2000px;
    width: 100%;
    border-radius: 15px;
    margin-right: 450px;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px,
      rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px,
      rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
  }

  .section2project1-section {
    margin-bottom: 45px;
  }

  .section2project1-section h1 {
    font-weight: 900;
  }

  .section2project1-section p {
    font-size: 17px;
  }
}

/* Tos */
.tos-section {
  width: 80%;
  margin: auto;
}

.tos-title {
  font-weight: 900;
  font-size: 22px;
}

@media (max-width: 520px) {
  /* Mobile home 500 */
  .home-placeholdervideo {
    border-radius: 10px;
    height: 300px;
    width: 100%;
  }

  .home-rightcontainer {
    margin-top: -50px;
    overflow-x: hidden;
    width: 100%;
  }

  .home-firstline {
    font-size: 25px;
    margin-top: 165px;
    font-weight: bold;
    width: 100%;
    color: white;
    text-align: center;
  }

  .home-secondline {
    font-size: 35px;
    margin-top: -25px;
    font-weight: bold;
    width: 100%;
    color: white;
    text-align: center;
  }

  .home-leftcontainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .home-checkmark {
    height: 20px;
    width: 20px;
    margin-right: 10px;
  }

  .home-listdiv {
    display: flex;
    text-align: center;
    justify-content: center;
  }

  .home-buttoncontainer {
    margin-top: -20px;
    margin-bottom: 50px;
  }

  .home-listul {
    padding-left: 0px;
    font-size: 15px;
    margin-top: 0px;
    color: white;
  }
  .home-listdiv p {
    margin-bottom: 5px;
    padding-bottom: 5px;
  }

  .home-button {
    margin-top: 30px;
    padding: 12px 30px;
    font-weight: bold;
    color: white;
    font-size: 15px;
    border: none;
    margin-bottom: 80px;
    margin-right: 15px;
    transition: background-color 0.3s ease-in-out;
    background-color: rgb(38, 0, 87);
    border-radius: 5px;
  }

  /* Mobile Section 2 500 */
  .section2-toprow {
    margin-left: 75px;
    width: 90%;
    display: flex;
    flex-direction: column;
    padding-top: 50px;
    padding-right: 0px;
    margin-right: 0px;
  }

  .section2-card {
    margin-right: 0px;
  }

  /* Mobile Section 3 500 */
  .section3-container {
    margin-left: 25px;
    margin-right: 25px;
    padding-bottom: 130px;
    padding-top: 130px;
    display: flex;
    flex-direction: column;
  }

  .section3-backgroundimg {
    background-image: url("../img/darkergraphicbg.jpg");
    background-attachment: fixed;
  }

  .section3-title {
    color: white;
    text-align: center;
    font-size: 35px;
    font-weight: 900;
  }

  .section3-subtitle {
    color: white;
    text-align: center;
    font-size: 17px;
    font-weight: lighter;
  }

  .section3-pop {
    font-weight: 900;
  }

  .section3-button {
    align-self: center;
    justify-self: center;
    margin-top: 20px;
    padding: 12px 70px;
    font-weight: bold;
    font-size: 20px;
    border: none;
    transition: background-color 0.3s ease-in-out;
    background-color: rgba(111, 0, 255, 255);

    border-radius: 5px;
  }

  .section3-button:hover {
    background-color: rgb(161, 94, 248);
    cursor: pointer;
  }

  .section3-link {
    align-self: center;
    justify-self: center;
    color: black;
  }

  .section3-link a {
    color: black;
  }

  /* Mobile Section 5  500 */
  .section5-container {
    /* margin-left: 55px; */
    /* margin-right: 55px; */
  }
  .section5-title {
    font-size: 38px;
    text-align: center;
  }

  .section5-subtitle {
    font-size: 15px;
    width: 70%;
    text-align: center;
  }
  .section5-contentcontainer {
    width: 40%;
    margin-left: 35px;
    font-size: 13px;
    margin-right: 20px;
  }

  .section5-contenttitle {
    font-size: 25px;
  }

  .section5-strategybtn {
    padding: 10px 15px;
    margin-left: 55px;
    font-size: 15px;
    font-weight: bolder;
    color: black;
    border: 1px solid #ccc;
    background-color: rgb(255, 255, 255);
    transition: background-color 0.3s ease-in-out;
  }

  .section5-maintainbtn {
    padding: 10px 15px;
    font-size: 15px;
    border: 1px solid #ccc;
    background-color: rgb(255, 255, 255);
    margin-right: 55px;
    font-weight: bolder;
    color: black;
    transition: background-color 0.3s ease-in-out;
  }

  /* Mobile Section 7 500 */
  .section7-title {
    color: white;
    font-size: 14px;
  }

  .section7-subtext {
    font-size: 12px;
    margin-right: -20px;
  }

  .section7-about {
    font-size: 12px;
    margin-top: 35px;
  }

  .section7-sections {
    width: 20%;
    margin-right: 15px;
  }

  .section7-logo {
    width: 70px;
    height: 20px;
  }

  .section7-nav {
    color: white;
    font-size: 12px;
    text-align: center;
    text-decoration: none;
  }

  .section7-nounderline {
    text-decoration: none;
  }

  .section7-nav1 {
    color: white;
    font-size: 12px;
    margin-top: 38px;
    text-align: center;
    text-decoration: none;
  }

  .section7-secondcol {
    display: none;
  }

  /* Mobile Section 2 Pricing 500  */
  .section2pricing-toprow {
    flex-direction: column;
    margin-left: 65px;
    height: 100%;
  }

  .section2pricing-card {
    padding-bottom: 0px;
  }

  /* Mobile Section 4 Pricing 1090 */
  .section4pricing-title {
    font-size: 35px;
    margin-left: 45px;
    width: 80vw;
  }

  /* Mobile Section 3 Pricing 500 */
  .section3pricing-container {
    display: flex;
    flex-direction: column;
    /* justify-content: space-around; */
    margin-left: 10px;
    margin-right: 10px;
  }

  .section3pricing-title {
    margin-left: 0px;
    font-size: 40px;
    width: 100%;
  }

  /* Mobile FAQ 500 */
  .faq-container {
    width: 90%;
    margin-left: 20px;
    /* margin-top: 120px; */
  }

  .faq-title {
    color: black;
    width: 100%;
    font-size: 45px;
    font-weight: 900;
    margin-left: 0px;

    text-align: center;
    padding-top: 50px;
    margin-top: 50px;
  }

  /* Mobile Section 2 Process 500 */

  .section2process-container {
    height: 100%;
  }

  .section2process-section {
    border-bottom: 1px solid rgb(216, 216, 216);
    margin-left: 25px;
    margin-right: 25px;
  }

  .section2process-title {
    color: black;
    font-size: 35px;
    font-weight: 900;
    text-align: center;
    padding-top: 150px;
    margin-left: 50px;
    margin-right: 50px;
  }

  .section2process-contentcontainer {
    width: 60%;
    margin-right: 50px;
    font-size: 13px;
  }

  .section2process-contentcontainer2 {
    width: 60%;
    font-size: 13px;
  }

  .section2process-strategy-text {
    display: flex;
    /* margin-left: 350px; */
    margin-top: 100px;
  }

  .section2process-img {
    width: 320px;
    height: 230px;
    margin-top: 50px;
  }

  .section2process-img2 {
    width: 320px;
    height: 230px;
    margin-right: 50px;
    margin-top: 55px;
  }

  /* Mobile Onboarding 500*/
  .onboarding-form {
    width: 85%;
    height: 580px;
    /* background-color: white; */
    background: linear-gradient(
      to bottom,
      #ffffff 0%,
      #ffffff 87%,
      rgba(111, 0, 255, 1) 87%,
      rgba(111, 0, 255, 1) 100%
    );
    border-radius: 15px;
    padding: 15px 15px;
    box-shadow: rgba(100, 100, 111, 0.4) 0px 7px 29px 0px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    justify-items: center;
    margin: auto;
    margin-bottom: 45px;
    margin-top: 30px;
  }

  .onboarding-questioncontainer {
    display: flex;
    flex-direction: column;
    /* justify-content: center; */
    align-items: center;
    height: 100%;
  }

  .onboarding-globaltitle {
    text-align: center;
    font-size: 35px;
    font-weight: 900;
    width: 95%;
    margin: auto;
    margin-top: 25px;
  }

  .onboarding-title {
    text-align: center;
    font-size: 18px;
    font-weight: 900;
    overflow-y: hidden;
  }

  .onboarding-textoption1 {
    margin-right: 15px;
    margin-bottom: 15px;
    border-radius: 5px;
    border: 1px solid black;
    padding: 15px 15px 35px 15px;
    width: 300px;
    overflow-y: hidden;
  }

  .onboarding-textoption1:hover {
    cursor: pointer;
    background-color: rgb(248, 248, 248);
  }

  .onboarding-textoption2 {
    margin-bottom: 15px;
    border-radius: 5px;
    border: 1px solid black;
    padding: 15px 15px 35px 15px;
    width: 300px;
    overflow-y: hidden;
  }

  .onboarding-margbottom {
    margin-bottom: 4px;
  }

  .onboarding-subtitle {
    text-align: center;
    font-weight: lighter;
    margin-bottom: 0px;
    padding-bottom: 0;
  }

  .onbording-sectioncontainer {
    flex-direction: column;
    margin-left: 10px;
  }

  .onboarding-textoption2:hover {
    cursor: pointer;
    background-color: rgb(248, 248, 248);
  }

  .onboarding-pagediv {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }

  .onboarding-next {
    font-size: 23px;
    font-weight: bold;
    color: white;
    overflow-y: hidden;
  }

  .onboarding-prev {
    font-size: 23px;
    font-weight: bold;
    color: white;
    overflow-y: hidden;
  }

  .onboarding-prev:hover {
    cursor: pointer;
  }

  .onboarding-next:hover {
    cursor: pointer;
  }

  .onboarding-arrow1 {
    width: 50px;
    height: 20px;
    margin-top: 8px;
    margin-left: 7px;
  }

  .onboarding-arrow2 {
    width: 50px;
    height: 20px;
    margin-top: 8px;
    margin-right: 7px;
  }

  .onboarding-arrow2:hover {
    cursor: pointer;
  }

  .onboarding-arrow1:hover {
    cursor: pointer;
  }

  .onboarding-preview {
    height: 500px;
    width: 130px;
    margin-right: 25px;
  }

  .onboarding-getstarted {
    margin-top: 130px;
    padding: 12px 70px;
    font-weight: bold;
    color: white;
    font-size: 20px;
    border: none;
    margin-bottom: 80px;
    margin-right: 15px;
    transition: background-color 0.3s ease-in-out;
    background-color: rgb(38, 0, 87);
    border-radius: 5px;
  }

  .onboarding-getstarted:hover {
    cursor: pointer;
  }

  .onboarding-googledoc {
    text-decoration: none;
    color: black;
    font-size: 25px;
    font-weight: bold;
    margin-right: 25px;
  }

  .onboarding-uploadimg {
    width: 32px;
    height: 32px;
    margin-right: 4px;
  }

  .onboarding-pricing {
    color: rgb(82, 82, 82);
    font-weight: 900;
    /* background-color: #6f00ff; */
    padding: 15px;
    width: 100%;
    border-radius: 5px;
    margin-top: 20px;
    text-align: center;
  }

  .onboarding-pricing:hover {
    color: rgb(27, 27, 27);
  }

  .onboarding-nostyle {
    text-decoration: none;
    overflow-y: hidden;
  }

  .onboarding-agreement {
    color: rgb(82, 82, 82);
    font-weight: 900;
    /* background-color: #6f00ff; */
    padding: 15px;
    width: 100%;
    border-radius: 5px;
    text-align: center;
    overflow-y: hidden;
    overflow-x: hidden;
    margin-top: -15px;
  }

  .onboarding-agreement:hover {
    color: rgb(27, 27, 27);
  }

  .onboarding-layoutnum {
    padding: 0;
    margin: 0;
    margin-left: 3px;
    margin-right: 5px;
    font-size: 23px;
    font-weight: 900;
  }

  .onboarding-hiddenanswers {
    z-index: -100;
  }

  .onboarding-layoutsection {
    display: flex;
    width: 340px !important;
    height: 400px !important;
    margin-left: 10px !important;
  }
}

@media (max-width: 420px) {
  /* XR section 2  */
  .section2-toprow {
    margin-left: 15px;
    margin-right: 0px;
    padding-left: 0px;
    padding-right: 0px;
    width: 100%;
    overflow-y: hidden;
  }

  .section2-card {
    width: 93%;
    margin-right: 0;
    /* margin: 0px; */
    /* padding: 0px; */
  }

  /* XR section 4 */
  .section4-cardtextcontainer {
    margin-bottom: -120px;
  }

  /* XR section 5 */
  .section5-background {
    margin-top: -150px;
  }

  /* XR section 1 pricing */
  .section1pricing-firstline {
    font-size: 35px;
    margin-top: 125px;
  }

  .section1pricing-secondline {
    font-size: 50px;
    margin-bottom: 0px;
  }

  .section1pricing-subtitle {
    margin-top: 10px;
    width: 90%;
  }

  /* XR Section 2 pricing */
  .section2pricing-toprow {
    margin-left: 10px;
    margin-right: 10px;
  }

  .section2-container {
    margin-top: -10px;
    padding-top: 0px;
    /* margin-left: 100x; */
    margin-left: 0px;
    margin-right: 0px;
    width: 100%;
    display: flex;
    flex-direction: column;
  }

  /* XR section 4 */
  .section4-cardtextcontainer {
    width: 92%;
    margin-left: 0px;
  }

  .section4-container {
    margin-top: -10px;
    padding-top: 0px;
    /* margin-left: 100x; */
    margin-left: 0px;
    margin-right: 0px;
    width: 100%;
    display: flex;
    flex-direction: column;
  }

  /* XR Section2 Portfolio */
  .section2portfolio-cardtextcontainer {
    width: 92%;
    margin-left: 0px;
  }

  .section2portfolio-container {
    margin-top: -10px;
    padding-top: 0px;
    /* margin-left: 100x; */
    margin-left: 0px;
    margin-right: 0px;
    width: 100%;
    display: flex;
    flex-direction: column;
  }

  /* XR section 3 pricing */
  .section3pricing-graphic {
    position: absolute;
    right: 0;
    width: 80px;
    height: 200px;
    margin-top: -400px;
    background-image: url("../img/tinygraphic1.jpg");
    background-size: 50px;
    background-repeat: no-repeat;
    /* animation: blowing 10s ease-in-out infinite; */
  }

  /* Xr section 2 pricing */
  .section2process-contentcontainer {
    width: 85%;
    margin-right: 5px;
  }

  .section2process-contentcontainer2 {
    width: 85%;
  }

  .section2process-img2 {
    margin-right: 10px;
  }

  /* XR contact */
  .contact-title {
    margin-top: 0px;
  }
}

@media (max-width: 380px) {
  /* SE section 1  */
  .home-button {
    padding: 12px 15px;
  }

  /* SE section 2 */
  .section2-toprow {
    margin-left: 15px;
    /* width: 100vw; */
    overflow-y: hidden;
  }

  .section2-card {
    /* width: 100%; */
  }

  /* SE section 3 pricing */
  .section3pricing-graphic {
    /* position: absolute;
    right: 0;
    width: 100px;
    height: 250px;
    margin-top: -1830px;
    background-image: url("../img/tinygraphic1.jpg");
    background-size: 60px;
    background-repeat: no-repeat; */
    position: absolute;
    right: 0;
    width: 60px;
    height: 120px;
    margin-top: -350px;
    background-image: url("../img/tinygraphic1.jpg");
    background-size: 40px;
    background-repeat: no-repeat;
    /* animation: blowing 10s ease-in-out infinite; */
  }

  /* SE section 2 process */
  .section5-contenttitle {
    font-size: 20px;
  }

  /* SE contact */
  .contact-title {
    margin-top: 0px;
    overflow-y: hidden;
    font-size: 35px;
  }

  .contact-subtitle {
    font-size: 16px;
  }

  /* SE Onboarding */
  .onboarding-textoption1 {
    margin-right: 15px;
    margin-bottom: 15px;
    border-radius: 5px;
    border: 1px solid black;
    padding: 15px 15px 35px 15px;
    width: 280px;
    overflow-y: hidden;
  }

  .onboarding-textoption1:hover {
    cursor: pointer;
    background-color: rgb(248, 248, 248);
  }

  .onboarding-textoption2 {
    margin-bottom: 15px;
    border-radius: 5px;
    border: 1px solid black;
    padding: 15px 15px 35px 15px;
    width: 280px;
    overflow-y: hidden;
  }

  .onbording-sectioncontainer {
    margin-left: 13px;
  }

  .onboarding-layoutsection {
    display: flex;
    width: 300px !important;
    height: 400px !important;
    margin-left: 10px !important;
  }
}
